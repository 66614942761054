<template>
  <div class="page-container">
    <el-tabs
        v-model="mainTabName"
        tab-position="left"
        @tab-click="(tab,event)=>clickMainTab(tab,event)"
    >
      <el-tab-pane label="第一步 题库选题" name="choose" v-if="exam.step===0">
        <div class="choose-question-container">
          <div class="title-info">
            <!--标题-->
            <div class="main-title">
              题库名称：{{ choose.questionBank['name'] }}
            </div>
          </div>
          <!--筛选-->
          <div class="clearfix">
            <list-search-filter
                :search-filter="choose.lists.searchFilter"
                @clickSearchFilterBtn="query=>ChooseMethods().clickSearchFilterBtn(query)"
            >
            </list-search-filter>
          </div>
          <!--试题类型分组-->
          <el-tabs
              v-model="choose.chooseQuestionType"
              type="border-card"
              @tab-click="(tab,event)=>ChooseMethods().clickListTab(tab,event)"
          >
            <el-tab-pane label="单项选择" name="Radio">
              <!--列表-->
              <el-table
                  v-loading="choose.lists.list[0].loading"
                  :data="choose.lists.list[0].list"
                  element-loading-text="加载中"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
                  @selection-change="list=>ChooseMethods().listSelectChange(list,0)"
              >
                <!--          <el-table-column label="编号" align="center" width="100px">-->
                <!--            <template slot-scope="scope">-->
                <!--              <span>{{ scope.row.id }}</span>-->
                <!--            </template>-->
                <!--          </el-table-column>-->
                <el-table-column label="编号" align="center" width="100px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="问题描述" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
                    <div v-else>{{ scope.row.content }}</div>
                  </template>
                </el-table-column>
                <!--渲染选项列表-->
                <el-table-column label="问题选项" align="center">
                  <template slot-scope="scope">
                    <div
                        v-for="item in scope.row.options"
                        align="center"
                    >
                      <span v-if="item.content">{{ item.choice + ':' + item.content }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    width="150"
                    fixed="right"
                    class-name="small-padding fixed-width"
                >
                  <template slot-scope="scope">
                    <el-button type="success" size="mini" round
                               @click="ChooseMethods().clickStartBtn('Radio',scope.row,scope.$index)">
                      开始答题
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!--列表分页-->
              <div class="pagination-container">
                <el-pagination
                    background
                    :current-page="choose.lists.list[0].pages.page"
                    :page-size="choose.lists.list[0].pages.limit"
                    layout="total,prev, pager, next,sizes"
                    :total="choose.lists.list[0].pages.totalNumber"
                    :page-count="choose.lists.list[0].pages.totalPage"
                    @current-change="(number)=>ChooseMethods().pageChange(0,number)"
                    @size-change="(size)=>ChooseMethods().pageLimitChange(0,size)"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="多项选择" name="Multiple">
              <!--列表-->
              <el-table
                  v-loading="choose.lists.list[1].loading"
                  :data="choose.lists.list[1].list"
                  element-loading-text="加载中"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
                  @selection-change="list=>ChooseMethods().listSelectChange(list,1)"
              >
                <el-table-column label="编号" align="center" width="100px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="问题描述" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
                    <div v-else>{{ scope.row.content }}</div>
                  </template>
                </el-table-column>
                <!--渲染选项列表-->
                <el-table-column label="问题选项(蓝色为正确选项)" align="center">
                  <template slot-scope="scope">
                    <div
                        v-for="item in scope.row.options"
                        align="center"
                    >
                      <span v-if="item.content">{{ item.choice + ':' + item.content }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    width="150"
                    fixed="right"
                    class-name="small-padding fixed-width"
                >
                  <template slot-scope="scope">
                    <el-button type="success" size="mini" round
                               @click="ChooseMethods().clickStartBtn('Multiple',scope.row,scope.$index)">
                      开始答题
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!--列表分页-->
              <div class="pagination-container">
                <el-pagination
                    background
                    :current-page="choose.lists.list[1].pages.page"
                    :page-size="choose.lists.list[1].pages.limit"
                    layout="total,prev, pager, next,sizes"
                    :total="choose.lists.list[1].pages.totalNumber"
                    :page-count="choose.lists.list[1].pages.totalPage"
                    @current-change="(number)=>ChooseMethods().pageChange(1,number)"
                    @size-change="(size)=>ChooseMethods().pageLimitChange(1,size)"
                />
              </div>
            </el-tab-pane>
            <el-tab-pane label="判断题" name="Judge">
              <!--列表-->
              <el-table
                  v-loading="choose.lists.list[2].loading"
                  :data="choose.lists.list[2].list"
                  element-loading-text="加载中"
                  border
                  fit
                  highlight-current-row
                  style="width: 100%;"
                  @selection-change="list=>ChooseMethods().listSelectChange(list,2)"
              >
                <el-table-column label="编号" align="center" width="100px">
                  <template slot-scope="scope">
                    <span>{{ scope.row.id }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="问题描述" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.contentType==='html'" class="content-vhtml" v-html="scope.row.content"/>
                    <div v-else>{{ scope.row.content }}</div>
                  </template>
                </el-table-column>
                <!--渲染选项列表-->
                <el-table-column label="问题选项" align="center">
                  <template slot-scope="scope">
                    <div>正确</div>
                    <div>错误</div>
                  </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="操作"
                    width="150"
                    fixed="right"
                    class-name="small-padding fixed-width"
                >
                  <template slot-scope="scope">
                    <el-button type="success" size="mini" round
                               @click="ChooseMethods().clickStartBtn('Judge',scope.row,scope.$index)">
                      开始答题
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!--列表分页-->
              <div class="pagination-container">
                <el-pagination
                    background
                    :current-page="choose.lists.list[2].pages.page"
                    :page-size="choose.lists.list[2].pages.limit"
                    layout="total,prev, pager, next,sizes"
                    :total="choose.lists.list[2].pages.totalNumber"
                    :page-count="choose.lists.list[2].pages.totalPage"
                    @current-change="(number)=>ChooseMethods().pageChange(2,number)"
                    @size-change="(size)=>ChooseMethods().pageLimitChange(2,size)"
                />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="第二步 课堂答题" name="exam" v-if="exam.step>0">
        <!-- 题目详情 -->
        <div class="question-container">
          <!-- 单选题 -->
          <div class="section-questions question-radio" v-if="exam.question.questionType==='Radio'">
            <div class="question-title" v-if="exam.question.contentType==='text'">
              {{ exam.question.content }}
            </div>
            <div class="question-title-html  flex flex-start" v-if="exam.question.contentType==='html'">
              <div style="float:left" class="content-vhtml" v-html="exam.question.content"></div>
            </div>
            <div class="options">
              <el-radio-group>
                <el-radio class="block" v-for="option in exam.question.options" :label="option.choice">
                  {{ option.choice }}、{{ option.content }}
                </el-radio>
              </el-radio-group>
            </div>
            <!--判断正确与否和展示答案-->
            <div class="answer" v-if="exam.step===3">
              <el-tag type="info" size="medium" class="right-answer">
                标准答案：{{ exam.question.standardAnswer }}
              </el-tag>
            </div>
            <div class="options-show" v-if="exam.step===3">
              <div class="block" v-for="option in exam.question.options">
                {{ option.choice }}、{{ option.content }}
                <div class="question-analysis per">
                  <div class="flex flex-start">
                    <span>选择次数：</span>
                    <span>{{ option.studentNameList.length }}次</span>
                  </div>
                  <div class="flex flex-start">
                    <span>选择比例：</span>
                    <el-progress
                        :percentage="exam.analysis.totalChooseNumber!==0?((option.studentNameList.length/exam.analysis.totalChooseNumber)*100).toFixed(0):0"
                        style="width: 150px;" color="#13ce66"></el-progress>
                  </div>
                  <div class="flex flex-start">
                    <span>选择学生：</span>
                    <span>{{ option.studentNameList.join("、") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--多项选择题-->
          <div class="section-questions question-multiple" v-if="exam.question.questionType==='Multiple'">
            <div class="question-title" v-if="exam.question.contentType==='text'">
             {{ exam.question.content }}
            </div>
            <div class="question-title-html clearfix" v-if="exam.question.contentType==='html'">
              <div style="float:left" class="content-vhtml" v-html="exam.question.content"></div>
            </div>
            <!--渲染选项-->
            <div class="options">
              <el-checkbox-group v-model="exam.question.tempAnswer">
                <el-checkbox class="block" v-for="option in exam.question.options" :label="option.choice">
                  {{ option.choice }}、{{ option.content }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="answer" v-if="exam.step===3">
              <el-tag type="info" size="medium" class="right-answer">
                标准答案：{{ exam.question.standardAnswers.join("、") }}
              </el-tag>
            </div>
            <div class="options-show" v-if="exam.step===3">
              <div class="block" v-for="option in exam.question.options">
                {{ option.choice }}、{{ option.content }}
                <div class="question-analysis per">
                  <div class="flex flex-start">
                    <span>选择次数：</span>
                    <span>{{ option.studentNameList.length }}次</span>
                  </div>
                  <div class="flex flex-start">
                    <span>选择比例：</span>
                    <el-progress
                        :percentage="exam.analysis.totalChooseNumber!==0?((option.studentNameList.length/exam.analysis.totalChooseNumber)*100).toFixed(0):0"
                        style="width: 150px;" color="#13ce66"></el-progress>
                  </div>
                  <div class="flex flex-start">
                    <span>选择学生：</span>
                    <span>{{ option.studentNameList.join("、") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 判断题 -->
          <div class="section-questions question-judge"  v-if="exam.question.questionType==='Judge'">
            <div class="question-title" v-if="exam.question.contentType==='text'">
             {{ exam.question.content }}
            </div>
            <div class="question-title-html clearfix" v-if="exam.question.contentType==='html'">
              <div style="float:left" class="content-vhtml" v-html="exam.question.content"></div>
            </div>
            <div class="options">
              <el-radio-group>
                <el-radio class="block" label="true">
                  正确
                </el-radio>
                <el-radio class="block" label="false">
                  错误
                </el-radio>
              </el-radio-group>
            </div>
            <div class="question-analysis-this" v-if="exam.step===3">
              <el-tag type="info" size="medium" class="right-answer">
                标准答案：{{ enums.questionJudgeAnswer[exam.question.standardAnswer] }}
              </el-tag>
            </div>
            <div class="options-show" v-if="exam.step===3">
              <div>正确</div>
              <div class="question-analysis per">
                <div class="flex flex-start">
                  <span>选择次数：</span>
                  <span>{{ exam.question.options[0].studentNameList.length }}次</span>
                </div>
                <div class="flex flex-start">
                  <span>选择比例：</span>
                  <el-progress
                      :percentage="exam.analysis.totalChooseNumber!==0?((exam.question.options[0].studentNameList.length/exam.analysis.totalChooseNumber)*100).toFixed(0):0"
                      style="width: 150px;" color="#13ce66"></el-progress>
                </div>
                <div class="flex flex-start">
                  <span>选择学生：</span>
                  <span>{{  exam.question.options[0].studentNameList.join("、") }}</span>
                </div>
              </div>
              <div>错误</div>
              <div class="question-analysis per">
                <div class="flex flex-start">
                  <span>选择次数：</span>
                  <span>{{ exam.question.options[1].studentNameList.length }}次</span>
                </div>
                <div class="flex flex-start">
                  <span>选择比例：</span>
                  <el-progress
                      :percentage="exam.analysis.totalChooseNumber!==0?((exam.question.options[1].studentNameList.length/exam.analysis.totalChooseNumber)*100).toFixed(0):0"
                      style="width: 150px;" color="#13ce66"></el-progress>
                </div>
                <div class="flex flex-start">
                  <span>选择学生：</span>
                  <span>{{ exam.question.options[1].studentNameList.join("、") }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <!-- 答题结果统计 -->
        <div class="analysis-container flex flex-center flex-dr" v-if="exam.step===3">
          <div class="title-box">答题统计分析</div>
          <div class="clazz-box flex flex-dr flex-center">
            <div class="name">{{ exam.statistic.clazzName }}</div>
            <div class="text">答题教学班级</div>
          </div>
          <div class="number-box flex flex-between">
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.analysis.useTime| minuteSecondFilter }}</div>
              <div class="text">答题时间</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.analysis.rightRate }}%</div>
              <div class="text">正确率</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.analysis.submitedRate }} %</div>
              <div class="text">完成率</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.analysis.submitedNumber }} / {{ exam.statistic.studentNumber }}</div>
              <div class="text">完成答题人数</div>
            </div>
          </div>
          <div class="button-box flex flex-dr flex-center">
            <el-button type="warning" @click="ExamMethods().clickNewQuestionBtn()">重新选题</el-button>
          </div>
        </div>
        <!-- 设置答题参数 -->
        <div class="setting-container flex flex-dr flex-center" v-if="exam.step===1">
          <div class="title-box">设置答题参数</div>
          <el-form label-width="120px" ref="exam_infoForm" :model="exam.info.edit"
                   :rules="exam.info.formRules">
            <el-form-item label="教学班级:" prop="clazzId">
              <el-select v-model="exam.info.edit.clazzId" placeholder="请选择班级"
                         :disabled="exam.info.type==='edit'"
                         style="width: 400px">
                <el-option v-for="(item,index) in exam.info.filter.clazzOptions" :value="item.value"
                           :key="index"
                           :label="item.label" v-if="item.value!==''"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="考核时间" prop="date">
              <el-date-picker
                  v-model="exam.info.edit.date"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00','23:59:59']"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="exam.info.datePickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="flex flex-center">
            <el-button type="success" @click="ExamMethods().clickStartSendQuestion()">开始发题</el-button>
          </div>
        </div>
        <!-- 显示答题状态各种统计信息 -->
        <div v-if="exam.step===2" class="flex flex-dr flex-center examInfo-container">
          <div class="qrcode-box flex flex-dr flex-center">
            <img :src="exam.qrcodeUrl" alt="" class="qrcode" @click="window.open(exam.qrcodeUrl)">
            <div class="text">手机扫码答题</div>
          </div>
          <div class="title-box">答题{{ enums.examStatus[exam.statistic.status] }}</div>
          <div class="date-box flex flex-dr flex-center">
            <div class="count-box flex flex-dr flex-center">
              <div class="number" v-if="exam.statistic.status==='Going'">{{
                  exam.statistic.leftTime | minuteSecondFilter
                }}
              </div>
              <div class="number" v-else>00:00</div>
              <div class="text">剩余作答时间</div>
            </div>
            <div class="origin-date">
              <div><span>规定答题时间：</span>{{exam.exam.startTime | dateFormat}} 至 {{exam.exam.endTime | dateFormat}}</div>
            </div>
          </div>
          <div class="clazz-box flex flex-dr flex-center">
            <div class="name">{{ exam.statistic.clazzName }}</div>
            <div class="text">答题教学班级</div>
          </div>
          <div class="number-box flex flex-between">
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.studentNumber }}</div>
              <div class="text">教学班总人数</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.unResultNumber }}</div>
              <div class="text">未接题人数</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.resultNumber }}</div>
              <div class="text">已接题人数</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.unSubmitedNumber }}</div>
              <div class="text">答题中人数</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.submitedNumber }}</div>
              <div class="text">完成作答人数</div>
            </div>
            <div class="li flex flex-dr flex-center">
              <div class="number">{{ exam.statistic.unSuccessNumber }}</div>
              <div class="text">未完成作答人数</div>
            </div>
          </div>
          <div class="button-box flex flex-around" style="width: 250px;">
            <el-button type="warning" @click="ExamMethods().endExam()">结束答题</el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>


  </div>
</template>

<script>
/**
 */
import {QuestionModel} from '@/model/exp/QuestionModel'
import ListSearchFilter from '../../components/list/listSearchFilter'
import {CommonModel} from '@/model/CommonModel'
import elDragDialog from '@/directive/el-drag-dialog'
import {msg_confirm, msg_confirm_choose, msg_err, msg_input, msg_success} from '@/utils/ele_component'
import {
  find_obj_from_arr_by_id,
  objectToLVArr,
  htmlAppend,
  arrToLVArr,
  isArrHasRepeat,
  validateMaxLength, date_format, isArraySame, getQuery
} from '@/utils/common'
import Tinymce from "@/components/Tinymce";
import {mapState} from 'vuex'
import {EnumsModel} from "@/model/EnumsModel";
import {QuestionBankModel} from "@/model/exp/QuestionBankModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {QuestionPaperModel} from "@/model/exp/QuestionPaperModel";
import {QuestionExamModel} from "@/model/exp/QuestionExamModel";
import {dateFormat, minuteSecondFilter} from "@/filter/filter";
import exam from "./exam.vue";
import {EXAM_H5_URL} from "@/model/ConfigModel";
import {ToolsModel} from "@/model/ToolsModel";

export default {
  name: 'examSingle',
  components: {ListSearchFilter, Tinymce},
  filters: {minuteSecondFilter,dateFormat},
  directives: {
    elDragDialog
  },
  watch: {},
  computed: {
    ...mapState({
      userInfo: state => state.user
    }),
  },
  props: {},
  data() {
    return {
      window:window,
      // 外部方法
      find_obj_from_arr_by_id: find_obj_from_arr_by_id,
      objectToLVArr: objectToLVArr,
      arrToLVArr: arrToLVArr,
      // 枚举
      enums: {
        questionJudgeAnswer: EnumsModel.questionJudgeAnswer,
        questionOptionLabel: EnumsModel.questionOptionLabel,
        questionBankType: EnumsModel.questionBankType,
        examStatus: EnumsModel.examStatus,
      },
      mainTabName: "choose",
      // 第一步：题库选题
      choose: {
        questionBankId: this.$route.query['bankId'],
        chooseQuestionType: 'Radio', // 题型选择tab框
        // tab显示顺序
        tabOrders: ['Radio', 'Multiple', 'Judge'],
        // 列表
        lists: {
          list: [
            { // 单选题
              list: [],
              loading: false,
              query: {
                questionType: 'Radio'
              },
              queryOri: {
                questionType: 'Radio'
              },
              pages: {
                limit: 10
              },
              selectList: []
            },
            { // 多选题
              list: [],
              loading: false,
              query: {
                questionType: 'Multiple'
              },
              queryOri: {
                questionType: 'Multiple'
              },
              pages: {
                limit: 10
              },
              selectList: []
            },
            { // 判断题
              list: [],
              loading: false,
              query: {
                questionType: 'Judge'
              },
              queryOri: {
                questionType: 'Judge'
              },
              pages: {
                limit: 10
              },
              selectList: []
            },
          ],
          searchFilter: {
            search: [
              {
                type: 'input',
                label: '问题描述',
                key: 'content',
                value: ''
              },
            ],
            filter: []
          }
        },
        // 题库详情
        questionBank: {},
      },
      // 第二步 课堂答题
      examStatisticTimer: undefined,
      exam: {
        // 答题前信息设置
        info: {
          addQuestionBankTypeSelectData: [],
          filter: {
            clazzOptions: [],
            clazzObject: {},
            clazzOrigin: [],
          },
          edit: {
            questionId: "",// 第一步选择的题目id
          },
          firstCheck: false,
          // 输入检测
          formRules: {
            'clazzId': {required: true, message: '请选择教学班级', trigger: 'change'},
            'date': {required: true, message: '请选择考核开放的时间周期', trigger: 'change'},
          },
          // 实验授权时间禁止选择时间
          datePickerOptions: {
            shortcuts: [
              {
                text: '5分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 5 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '10分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 10 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '20分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 20 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '30分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 30 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '45分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 45 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '60分钟内',
                onClick(picker) {
                  let start = new Date();
                  let end = new Date();
                  end = new Date(end.getTime() + 60 * 60 * 1000)
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '本日内',
                onClick(picker) {
                  let start = new Date();
                  const end = new Date();
                  start.setHours(0, 0, 0, 0);
                  end.setHours(23, 59, 59, 59);
                  picker.$emit('pick', [start, end]);
                }
              },
              {
                text: '本周内',
                onClick(picker) {
                  let date = new Date();
                  const end = new Date();
                  const dayOfWeek = date.getDay();
                  const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is Sunday
                  const start = new Date(date.setDate(diff));
                  // 将日期调整为星期一
                  start.setDate(start.getDate() - start.getDay() + 1);
                  start.setHours(0, 0, 0, 0);
                  picker.$emit('pick', [start, end]);
                }
              },
            ],
            disabledDate: (time) => {
              return time.getTime() < Date.now() - 8.64e7
            }
          },
        },
        step: 0,// 考核的步骤
        exam: {},// 考核信息
        statistic: {
          results: [],
        },// 考核统计信息
        question: {},// 题目信息
        analysis: {
          totalChooseNumber: 0,
          rightRate: 0,
          rightNumber: 0,
          submitedRate: 0,
          submitedNumber: 0,
        },// 结果统计信息
        students: [],// 教学班里面的用户列表
        studentsObject: [],// 教学班里面的用户列表-对象
      },
    }
  },
  async mounted() {
    this.getBankInfo()
    // 题库选题-获取列表-单项选择列表
    this.ChooseMethods().getList(0, 1, this.choose.lists.list[0].pages.limit, this.choose.lists.list[0].query)
    // 题库选题-初始化筛选
    this.ChooseMethods().initFilter()

    if(getQuery("examId")){
      this.ExamMethods().init(parseInt(getQuery("examId")))
    }
    // 答题-初始化筛选
    this.ExamMethods().initFilter()
  },
  methods: {
    minuteSecondFilter,
    // 获取题库详情
    async getBankInfo() {
      if (this.choose.questionBankId) {
        const data = await QuestionBankModel.getOne(this.choose.questionBankId)
        if (data) {
          this.choose.questionBank = data
        } else {
          msg_err('未找到该题库信息')
        }
      }
    },
    // 列表Methods
    ChooseMethods() {
      const $this = this
      return {
        // 获取列表
        async getList(index, page, limit, query) {
          $this.choose.lists.list[index].loading = true
          // 如果有questionBankId作为路由参数
          if ($this.$route.query['bankId']) {
            query['questionBankId'] = $this.$route.query['bankId']
          }
          [$this.choose.lists.list[index].list, $this.choose.lists.list[index].pages] = await QuestionModel.getList(page, limit ? limit : 10, query)
          $this.choose.lists.list[index].loading = false
          $this.choose.lists.tabIndex = index;
        },
        // 分页-改变页码
        async pageChange(index, page) {
          this.getList(index, page, $this.choose.lists.list[index].pages.limit, $this.choose.lists.list[index].query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(index, limit) {
          this.getList(index, $this.choose.lists.pages.list[index].page, limit, $this.choose.lists.list[index].query)
        },
        // 初始化筛选列表
        async initFilter() {

        },
        // 点击搜索按钮
        clickSearchFilterBtn(query) {
          const index = $this.choose.tabOrders.indexOf($this.choose.chooseQuestionType)
          $this.choose.lists.list[index].query = Object.assign(query, $this.choose.lists.list[index].queryOri)
          this.getList(index, 1, $this.choose.lists.list[index].pages.limit ? $this.choose.lists.list[index].pages.limit : 10, $this.choose.lists.list[index].query)
        },
        // 点击tab切换
        clickListTab(tab, event) {
          const index = $this.choose.tabOrders.indexOf(tab.name)
          $this.choose.lists.tabIndex = index
          if ($this.choose.lists.list[index].list.length === 0) {
            this.getList(index, 1, $this.choose.lists.list[index].pages.limit, $this.choose.lists.list[index].query)
          }
        },
        // 点击开始答题按钮
        async clickStartBtn(questionType, question, questionIndex) {
          // 设置题目信息 初始化
          $this.$set($this.exam.info, "edit", {
            questionId: question.id,
          });
          if(questionType==="Judge"){
            question.options=[{choice:"true"},{choice:"false"}]
          }
          question.tempAnswer=[];
          question.options.forEach(li => {// 遍历问题选项初始化空学生列表
            li.studentNameList = []
          })
          $this.$set($this.exam, "question", question);
          $this.$set($this.exam, "step", 1);
          $this.mainTabName = "exam"
        }
      }
    },
    // 答题Methods
    ExamMethods() {
      let $this = this;
      return {
        // 初始化
        async init(examId){
          // 获取和设置考核信息
          $this.mainTabName="exam"
          let exam = await QuestionExamModel.getOne(examId)
          $this.$set($this.exam, "exam", exam) // 设置考核信息

          // 获取题目信息
          let paper = await QuestionPaperModel.getOne(exam.paperId);
          let sectionInfos = JSON.parse(paper.sectionInfos);
          let questionId = sectionInfos[0].questionIds[0];
          let question = (await QuestionModel.getList(1,100,{
            id:questionId
          }))[0][0];
          if(question.questionType==="Judge"){
            question.options=[{choice:"true"},{choice:"false"}]
          }
          question.tempAnswer=[];
          question.options.forEach(li => {// 遍历问题选项初始化空学生列表
            li.studentNameList = []
          })
          $this.$set($this.exam, "question", question);

          $this.mainTabName = "exam"


          this.getQrCode();

          $this.$set($this.exam, "step", 2) // 设置考核步骤
          // 轮询-统计状态
          await this.getExamStatistic();// 先主动获取一次
          $this.examStatisticTimer = setInterval(() => {
            this.getExamStatistic()
          }, 2000);// 每几秒循环一次

        },
        // 初始化筛选
        async initFilter() {
          // 获取教学班级列表
          let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
          let dataArr = []
          clazzList.forEach(li => {
            dataArr.push({
              label: li.clazzName,
              value: li.clazzid
            })
          })
          $this.$set($this.exam.info.filter, "clazzOptions", dataArr)
          $this.$set($this.exam.info.filter, "clazzOrigin", clazzList)
        },
        // 点击开始发题
        async clickStartSendQuestion() {
          $this.$refs['exam_infoForm'].validate(async validate => {
            if (validate) {
              // 创建一个考卷
              let paperId = await this.createNewPaper();
              // 创建考核
              let examId = await this.createExam(paperId);
              // 增加页面参数，重新刷新页面，进入下一流程 这样保证页面刷新也能显示当前考核
              window.location.href=`/teacher/examSingle?bankId=${$this.choose.questionBankId}&examId=${examId}`
            }
          })
        },
        // 打卡考核二维码
        async getQrCode(){
          // 打开考核二维码
          let url = EXAM_H5_URL
          let qrcodeUrl = await ToolsModel.getTextQrCode(url)
          $this.exam.qrcodeUrl=qrcodeUrl;
        },
        // 新建一个考卷
        async createNewPaper() {
          let paper = {
            name: "课堂单题卷 " + date_format(new Date(), "yyyy-MM-dd HH:mm"),
            totalScore: 1,
            passScore: 1,
            experimentId: $this.choose.questionBank.experimentId,
            type: "single",// 标记是课堂单题
            creatorId: $this.userInfo.userid,
            questionNumber: 1,
            sectionInfos: JSON.stringify([// 只有一个小节和题型
              {
                "sectionType": $this.exam.question.questionType,
                "sectionName": EnumsModel.questionType[$this.exam.question.questionType],
                "sectionQuestions": [],
                "scorePerQuestion": 1,
                "questionTotal": 1,
                "sectionTotalScore": 1,
                "questionIds": [$this.exam.question.id]
              }
            ])
          };
          let paperId = await QuestionPaperModel.save(paper)
          if (!paperId) {
            msg_err("发题失败-创建考卷失败！")
            return
          }
          return paperId
        },
        // 新建一个考核
        async createExam(paperId) {
          // 构建参数
          let entity = $this.exam.info.edit;
          if (new Date(entity.date[1]).getTime() <= new Date(entity.date[0]).getTime()) {
            msg_err("开始时间不能小于结束时间!")
            return
          }
          let exam = {
            name: "课堂单题考核 " + date_format(new Date(), "yyyy-MM-dd HH:mm"),
            type: "single",
            startTime: entity.date[0],
            endTime: entity.date[1],
            creatorId: $this.userInfo.userid, // 创建人
            paperId: paperId,
            experimentId: $this.choose.questionBank.experimentId,
            clazzId: entity.clazzId,
          }
          let examId = await QuestionExamModel.save(exam)
          if (!examId) {
            msg_err("发题失败-创建考卷失败！")
            return
          }
          return examId
        },
        // 获取考核统计状态
        async getExamStatistic() {
          let data = await QuestionExamModel.getOneExamStatisticInfo({examId: $this.exam.exam.id})
          let resultNumber = data.results.length;
          let unSubmitedNumber = 0;
          for (let result of data.results) {
            if (result.status === "UnSubmited") {
              unSubmitedNumber++;
            }
          }
          let submitedNumber = resultNumber - unSubmitedNumber;
          data.resultNumber = resultNumber;// 已接题人数
          data.unResultNumber = data.studentNumber - resultNumber;// 未接题人数
          data.unSubmitedNumber = unSubmitedNumber;// 答题中人数
          data.submitedNumber = submitedNumber; // 接题后已完成作答人数
          data.unSuccessNumber = data.studentNumber - submitedNumber;
          // 遍历，判断已接题人数中的已完成作答人数
          $this.$set($this.exam, "statistic", data);

          // 如果考核已结束
          if (data.status === "Over") {
            await this.endExam(true);
          }
        },
        // 点击结束考核
        async endExam(direct) {
          if (!direct) {
            if (await msg_confirm("确认要结束此场考核吗？结束后学生将不能继续答题！")) {

            } else {
              return
            }
          }
          clearInterval($this.examStatisticTimer)
          await QuestionExamModel.endExam({
            examId: $this.exam.exam.id
          })
          await this.getOneExamResultAnalysis()
          setTimeout(()=>{
            this.getOneExamResultAnalysis()
          },5000)// 5秒后再次刷新，已解决学生自动交卷问题
          $this.$set($this.exam, "step", 3) // 设置考核步骤
        },
        // 获取某个考核的结果分析
        async getOneExamResultAnalysis() {
          // 更新考核信息
          let exam = await QuestionExamModel.getOne($this.exam.exam.id);
          $this.$set($this.exam, "exam", exam) // 设置考核信息
          // 获取统计信息
          let data = await QuestionExamModel.getOneExamResultAnalysis({examId: $this.exam.exam.id})
          // 答题时间 就是这场考核的结束时间
          data.useTime = new Date(exam.endTime).getTime() - new Date(exam.startTime).getTime()
          // 获取答卷和用户列表和问题信息
          let results = data.results
          let students = data.students
          $this.$set($this.exam, "students", students);
          let studentsObject = {}
          for (let student of students) {
            studentsObject[student["userid"]] = student["name"]
          }
          $this.$set($this.exam, "studentsObject", studentsObject);
          let question = $this.exam.question
          let questionId = question.id
          let questionOptions = question.options // 问题选项
          let questionType = question["questionType"] // 问题类型
          let standAnswer = question.standardAnswer // 单项和判断的标准答案
          let standAnswers = question.standardAnswers // 多选的标准答案
          // 获取提交答卷人数和计算每个选项的答题人数
          let submitedNumber = 0;
          let rightNumber = 0; // 计算总正确人数
          let totalChooseNumber = 0; // 计算总的答案选择次数
          for (let result of results) {
            if (result.status !== "UnSubmited") {
              submitedNumber++;
            }
            let objContent = JSON.parse(result.objContent) // 当前只有学生的客观题答题结果
            let studentAnswer = objContent[questionId]
            if(studentAnswer===null||studentAnswer===undefined){
              continue
            }
            // 判断答案正确与否
            if (questionType === "Radio" || questionType === "Judge") {
              if (studentAnswer === standAnswer) {
                rightNumber++;
              }
              // 设置每个答案的选择人员列表
              let [optionIndex] = find_obj_from_arr_by_id("choice", studentAnswer, questionOptions);
              if(questionOptions[optionIndex].studentNameList.indexOf(studentsObject[result["userId"]])===-1){
                questionOptions[optionIndex].studentNameList.push(studentsObject[result["userId"]])
              }// 如果不存在再加
              totalChooseNumber++;
            }
            if (questionType === "Multiple") {
              if (isArraySame(studentAnswer, standAnswers)) {
                rightNumber++;
              }
              // 设置每个答案的选择人员列表
              studentAnswer.forEach(li => {
                let [optionIndex] = find_obj_from_arr_by_id("choice", li, questionOptions);
                if(questionOptions[optionIndex].studentNameList.indexOf(studentsObject[result["userId"]])===-1){
                  questionOptions[optionIndex].studentNameList.push(studentsObject[result["userId"]])
                }// 如
                totalChooseNumber++;
              })
            }
          }
          data.submitedNumber = submitedNumber;
          data.submitedRate = ((submitedNumber / $this.exam.statistic.studentNumber) * 100).toFixed(0)
          data.rightNumber = rightNumber
          data.rightRate = rightNumber !== 0 ? ((rightNumber / results.length) * 100).toFixed(0) : 0;
          data.totalChooseNumber = totalChooseNumber;
          // 完成率 最终提交答卷的人数和班级总人数之比
          $this.$set($this.exam, "analysis", data)
        },
        // 点击重新选题按钮
        async clickNewQuestionBtn() {
          window.location.href=`/teacher/examSingle?bankId=${$this.choose.questionBankId}`
        },
      }
    }
  }
}
</script>

<style>
.match-question .el-input__inner {
  text-align: center;
}
</style>
<style lang="less" scoped>
.markRight {
  color: #2bb7ff;
}

.main-title {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: #444;
  margin-bottom: 5px;
}

.edit-choose-item {
  margin-bottom: 10px;
}

// 题目容器
.question-container {
  padding: 20px;

  .section-questions {
    .question-title, .question-title-html {
      margin-bottom: 15px;
    }

    .options {
      .el-radio, .el-checkbox {
        margin-bottom: 10px;
      }
    }
  }

  .question-blank, .question-multiple {
    margin-bottom: 20px;
  }

  // 显示选项列表
  .options-show {
    margin-bottom: 10px;

    > div {
      font-size: 15px;
      color: #555;
      margin-top: 10px;
    }

    .question-analysis {
      > div {
        margin-top: 5px;

        > span:first-child {
          color: #666;
        }
      }
    }
  }
}

.title-box {
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.examInfo-container {
  position: relative;
  div.qrcode-box{
    position: absolute;
    width: 300px;
    height: 300px;
    right: 100px;
    top:-10px;
    img.qrcode{
      width: 300px;
      height: 300px;
      cursor: pointer;
    }
    div.text{
      color: #555;
      font-size: 14px;
      margin-top: -5px;
    }
  }
  .title-box {
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #67c23a;
    margin-bottom: 30px;
  }

  .count-box {
    .number {
      font-size: 50px;
      font-weight: bold;
    }

    .text {
      margin-top: 10px;
      color: #555;
      font-size: 15px;
    }
  }

  .origin-date {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 30px;

    span {
      color: #777;
    }
  }

  .clazz-box {
    margin-top: 15px;

    .name {
      font-size: 30px;
      font-weight: bold;
    }

    .text {
      margin-top: 10px;
      color: #555;
      font-size: 15px;
    }
  }

  .number-box {
    margin-top: 30px;

    .li {
      width: 200px;

      .number {
        font-size: 30px;
        font-weight: bold;
      }

      .text {
        font-size: 15px;
        color: #555;
        margin-top: 5px;
      }
    }
  }

  .button-box {
    margin-top: 50px;
    width: 200px;
  }
}

.analysis-container {
  .title-box {
    margin-bottom: 20px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: #67c23a;
    margin-bottom: 50px;
  }

  .count-box {
    .number {
      font-size: 50px;
      font-weight: bold;
    }

    .text {
      margin-top: 10px;
      color: #555;
      font-size: 15px;
    }
  }

  .origin-date {
    font-size: 15px;
    margin-top: 15px;

    span {
      color: #777;
    }
  }

  .clazz-box {
    margin-top: 15px;

    .name {
      font-size: 25px;
      font-weight: bold;
    }

    .text {
      margin-top: 10px;
      color: #555;
      font-size: 15px;
    }
  }

  .number-box {
    margin-top: 30px;

    .li {
      width: 200px;

      .number {
        font-size: 30px;
        font-weight: bold;
      }

      .text {
        font-size: 15px;
        color: #555;
        margin-top: 5px;
      }
    }
  }

  .button-box {
    margin-top: 50px;
    width: 200px;
  }
}

.content-vhtml p {
  margin: 0;
  margin-bottom: 5px;
}
</style>
